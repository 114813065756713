export function useHotMatchApi() {
  const apiPromotionVersion = useRuntimeConfig().API_PROMOTION
  const API_VER = useRuntimeConfig().API_VER_1
  return {
    GET_LINK_C_SPORT: `${API_VER}/athena/sportUrl`,
    GET_LINK_K_SPORT: `${API_VER}/tp/ksportUrl`,
    GET_LINK_P_SPORT: `${API_VER}/ssportUrl`,
    LATEST_SPORT_BET: `${API_VER}/currentSportBet`,
    GET_LINK_S_SPORT: `${API_VER}/athena/sportUrl`,
    GET_LINK_M_SPORT: `${API_VER}/imsportUrl`,
    GET_LINK_T_SPORT: `${API_VER}/tsportUrl`,
    GET_LINK_E_SPORT: `${API_VER}/athena/esportsUrl`,
    GET_LINK_VIRTUAL: `${API_VER}/athena/virtualSportUrl`,
    GET_HOT_MATCH: `${apiPromotionVersion}/hotmatch/hot`,
    GET_MATCH_FIXTURE_BY_DATE: `${apiPromotionVersion}/hotmatch/fixtureByDate`,
    GET_MATCH_FIXTURE: `${apiPromotionVersion}/hotmatch/fixture`,
    GET_MATCH_LEAGUE: `${apiPromotionVersion}/hotmatch/leagues`
  }
}
